import React, { useState, useEffect, useReducer } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/SaveAlt';
import CloudUpload from '@material-ui/icons/CloudUpload';
import ShareIcon from '@material-ui/icons/Share';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import pink from '@material-ui/core/colors/pink';
import blue from '@material-ui/core/colors/blue';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import axios from 'axios';
import ImageFadeIn from 'react-image-fade-in'


const steps = {
  UPLOAD: 'upload',
  CAPTION_INPUT: 'caption_input',
  SELECT_FACE: 'select_face',
  WAIT: 'wait',
  CAPTION_GENERATED: 'caption_generated',
  CAPTION_READY: 'caption_ready',
  ERROR: 'error'
}

function CurrentStep(props) {
  if( props.currentStep === steps.UPLOAD ) {
    return (
    <React.Fragment>
      <Grid container justify="center">
          <ImageFadeIn onClick={props.cloudinaryWidgetOpen} className={props.classes.logo} src="/icon_192.png" />
      </Grid>        
      <Grid container justify="center">
        <Typography component="h1" variant="h5" align="center">
          Upload your photo 📷, then add a caption 💬
        </Typography>        
      </Grid>
      <Grid container justify="center">
      <Button variant="contained" color="primary" onClick={props.cloudinaryWidgetOpen} className={props.classes.submit}>
      <CloudUpload className={props.classes.icon} />&nbsp;
        Upload Photo
      </Button>
      </Grid>
    </React.Fragment>
  )
  } else if ( props.currentStep === steps.CAPTION_INPUT ) {
    return(    
    <React.Fragment>
      <Grid container justify="center">
      <Typography component="h1" variant="h4" align="center">
        Type in your caption 💬
      </Typography>
      </Grid>
      <Grid container justify="center">
      <Typography component="h1" variant="h6" align="center">
        You can use English, Emoji (😀😄😆) and Chinese (你好)
      </Typography>
      </Grid>      
     <form noValidate autoComplete="off" onSubmit={props.handleCaptionSubmit} className={props.classes.form}>
    <Grid container justify="center">
    <TextField id="outlined-basic" name="caption" label="Your Caption" variant="outlined" autoFocus={true}   inputProps={{ maxLength: 140 }} fullWidth/>
    <Button
        type="submit"
        variant="contained"
        color="primary"
        className={props.classes.submit}
      >OK
      </Button>
    </Grid>
  </form>
  </React.Fragment> )
  } else if ( props.currentStep === steps.SELECT_FACE ) {
    // must select face
    return (
      <React.Fragment>
        <Grid container justify="center">
        <Typography component="h1" variant="h5" align="center">
          Please select a face
        </Typography>
        </Grid>
        <Grid container justify="center" spacing={2}>
        {props.faceSelection.map((element, i) => {
          return ( <Grid item><Avatar key={i+1} onClick={() => props.selectFaceId(i+1)} src={element.face_url} className={props.classes.avatar} /></Grid>)
        })}
        </Grid>
      </React.Fragment>
    )
  } else if ( props.currentStep === steps.WAIT || props.currentStep === steps.CAPTION_GENERATED) {
    return( 
    <React.Fragment>
      <Grid container justify="center">
      <Typography component="h1" variant="h5" align="center">
        Please Wait
      </Typography>
      </Grid>
      <Grid container justify="center">
      <CircularProgress />
      </Grid>      
   </React.Fragment>
   )
  } else if ( props.currentStep === steps.ERROR ) {
    return( 
    <React.Fragment>
      <Grid container justify="center">
      <Typography component="h1" variant="h5" align="center">
       Sorry an error has occured
      </Typography>
      </Grid>
      <Grid container justify="center">
      <Typography component="h6" variant="h6" align="center">
       {props.errorText}
      </Typography>
      </Grid>      
      <Grid container justify="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={props.handleUploadNewPhoto} className={props.classes.submit}>
            <CloudUpload className={props.classes.icon} />&nbsp;
            Try Again</Button>
        </Grid>        
      </Grid>
   </React.Fragment>
   )     
  } else if ( props.currentStep === steps.CAPTION_READY ) {
    return(     
    <React.Fragment>
    <Grid container justify="center"  spacing={2}>
      <Grid item>
        <Button variant="contained" color="primary" onClick={props.handleUploadNewPhoto} className={props.classes.submit}>
          <CloudUpload className={props.classes.icon} />&nbsp;
          New Photo</Button>
      </Grid>
      <Grid item>
         { props.shareInfo.share_button_available ? (
              <Button variant="contained" color="secondary" className={props.classes.saveButton} onClick={props.shareImage}>
              <ShareIcon className={props.classes.icon} />&nbsp;
              Share</Button>
            ) :  ( <Link href={props.finalUrlSave} underline='none'><Button variant="contained" color="secondary" className={props.classes.saveButton}>
              <SaveIcon className={props.classes.icon} />&nbsp;
              Save</Button></Link>)
         }
      </Grid>
    </Grid>
    { props.shareInfo.ios_message ? (
      <Grid container justify="center"  spacing={2}>
        <Grid item>
          <Typography component="h1" variant="h6" align="center">
          💡 long-press 👇 the image to share ✉ or save to photos 📷
          </Typography>          
        </Grid>
      </Grid>
    ) : ("") }
    <Grid container justify="center">
      <div className={props.classes.imageContainer}>
        <ImageFadeIn src={props.finalUrl} className={props.classes.image} />
      </div>
    </Grid>
    </React.Fragment> );
  } else {
    return (<b>error</b>)
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: blue[500],
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: pink[300],
    },
  },
});


function App() {

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(1),
    },
    saveButton: {
      margin: theme.spacing(1),
    },    
    mainContainer: {
      marginTop: theme.spacing(2),
    },
    logo: {
      cursor: 'pointer',
    },
    appbar: {
    },
    avatar: {
      height: 150,
      width: 150,
      marginTop: theme.spacing(2),
      cursor: 'pointer',
    },
    phobbleLogo: {
      maxWidth: 40,
      height: 'auto',
      marginRight: theme.spacing(2),
    },
    imageContainer: {
      maxWidth: '100%',
    },
    image: {
      display: 'block',
      width: '100%',
      height: 'auto'
    }
    
  }));
  
  const classes = useStyles();
  

  const initialState = {
    currentStep: steps.UPLOAD,
    uploadInProgress: false,
    finalUrl: null,
    finalUrlSave: null,
    captionSubmitted: false,
    captionText: "",
    faceSelection: null,
    faceId: 0,
    retrieveCaptionInProgress: false,
    shareButtonAvailable: false,
    errorText: ''
  };

  function stateReducer(state, action) {
    console.log("stateReducer: ", action);
    if (false) {
      return state;
    } else if (action.type === 'reset') {
      return initialState;
    } else if (action.type === 'external_upload') {
      return {...state, 'currentStep': steps.CAPTION_INPUT};
    } else if (action.type === 'upload_in_progress') {
      return {...state, 'currentStep': steps.CAPTION_INPUT};
    } else if (action.type === 'caption_submit') {
      return {...state, 'captionSubmitted': true, captionText: action.caption_text, currentStep: steps.WAIT};
    } else if (action.type === 'face_selection') {
      return {...state, 'faceSelection': action.face_list, currentStep: steps.SELECT_FACE};
    } else if (action.type === 'select_face_id') {
      return {...state, 'faceId': action.face_id, currentStep: steps.WAIT};
    } else if (action.type === 'caption_generated') {
      return {...state, 
              currentStep: steps.CAPTION_GENERATED,
              'finalUrl': action.url, 
              'finalUrlSave': action.url_save};      
    } else if (action.type === 'caption_complete') {
      return {...state, 
              'shareInfo': action.share_info,
              currentStep: steps.CAPTION_READY};
    } else if (action.type === 'error') {
      return {...state, currentStep: steps.ERROR, errorText: action.error_text};
    } else {
      throw new Error('unknown action.type ' + action.type);
    }
  }

  const [cloudinaryWidget, setCloudinaryWidget] = useState(null);
  const [captionRetrieveStarted, setCaptionRetrieveStarted] = useState(false);
  // keep this outside as the main state as I don't want a component re-render which would wipe 
  // out the text inputted so far
  const [uploadedPublicId, setUploadedPublicId] = useState(null);
  const [state, dispatch] = useReducer(stateReducer, initialState);

  
  function getApiUrl() {
    if (process.env.NODE_ENV === "development") {
      return 'http://localhost:5000/get_caption';
    }
    return '/get_caption';
  }

  function getUploadPreset() {
    if (process.env.NODE_ENV === "development") {
      return 'phobble_dev';
    }
    return 'phobble_prod';    
  }

  // check whether we can pre-load the final phobble
  useEffect(() => {  
    if( state.currentStep === steps.CAPTION_GENERATED) {
      // fetch the url
      console.log("fetching: ", state.finalUrl);
      fetch(state.finalUrl)
      .then(
        async function(response) {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
              response.status);
            return;
          }

          var dispatchObj = {
            type: 'caption_complete',
            share_info: {
              ios_message: false,
              share_button_available: false,
              share_data: null
            }
          }

          if (navigator.share) { 
            const blob = await response.blob();
            const file = new File([blob], 'phobble.jpg', {type: blob.type});
            
            const shareData = {
              title: "Phobble",
              text: "www.phobble.com",
              files: [file],
            };

            if (navigator.canShare && navigator.canShare(shareData)) {
              dispatchObj.share_info.share_data = shareData;
              dispatchObj.share_info.share_button_available = true;
            } else {
              // check whether we are on iOS
              const ua = window.navigator.userAgent;
              const touchBrowser = 'ontouchstart' in document.documentElement;
              const iOS = !!ua.match(/AppleWebKit/i);
  
              if( touchBrowser && iOS ) {
                dispatchObj.share_info.ios_message = true;
              }
            }
          } 

          dispatch(dispatchObj);
        }
      )
      .catch(function(err) {
        console.log('Fetch Error :-S', err);
        dispatch({type: 'error', error_text:err});
      });

    }
  });    

  // check whether we've got a public_id in the URL (file got uploaded from flask)
  useEffect(() => {  
    if( uploadedPublicId == null) {
      if( window.location.search != null) {
        var publicId = window.location.search.substr(1, window.location.search.length);
        if(publicId.length > 0) {
          console.log("public_id: ", publicId);
          setUploadedPublicId(publicId);
          dispatch({type: 'external_upload'});
        }
      }
    }
  }, [uploadedPublicId, setUploadedPublicId]);  

  useEffect(() => {
    if( cloudinaryWidget === null) {
      console.log("initial setup");
      var widget = window.cloudinary.createUploadWidget(
        {cloudName: "photozzap",
        uploadPreset: getUploadPreset(),
        sources: ["local"],
        multiple: false,
        showPoweredBy: false,
        showUploadMoreButton: false,
        autoMinimize:true,
        resourceType:'image'},
        (error, result) => { processWidgetResult(result); }
      );
      setCloudinaryWidget(widget);
    }
  }, [cloudinaryWidget, setCloudinaryWidget]);  

  // check whether we can retrieve the caption
  useEffect(() => {
    function checkRetrieveCaption(public_id, caption_text, caption_submitted, face_id, currentStep) {
      if( public_id != null && 
         caption_submitted === true && 
         captionRetrieveStarted === false && 
         currentStep !== steps.CAPTION_READY && 
         currentStep !== steps.SELECT_FACE) {
          setCaptionRetrieveStarted(true);
          retrieveCaption(public_id, caption_text, face_id);
      }
    }

    checkRetrieveCaption(uploadedPublicId, state.captionText, state.captionSubmitted, state.faceId, state.currentStep);
  }, [uploadedPublicId, state.captionText, state.captionSubmitted, state.faceId, captionRetrieveStarted, state.currentStep]);

  function retrieveCaption(public_id, caption, faceId) {
    console.log("retrieveCaption: ", public_id, " ", caption, " ", faceId);
    axios.post(getApiUrl(), {
      public_id: public_id,
      caption: caption,
      face_id: faceId
    })
    .then(function (response) {
      console.log(response);
      if (response.data.status === 'select_face') {
        // we will need to retrieve the caption again
        setCaptionRetrieveStarted(false);
        console.log('select_face');
        const face_list = response.data.face_list;
        dispatch({type: 'face_selection', face_list: face_list});
      } else if (response.data.status === 'caption_complete') {
        console.log('caption_complete')
        const result_url = response.data.result_url;
        const result_url_save = response.data.result_url_save;
        dispatch({type: 'caption_generated', url: result_url, url_save: result_url_save});
      }

    })
    .catch(function (error) {
      console.log(error);
      dispatch({type: 'error', error_text:error});
    });     
  }

  function processWidgetResult(resultEvent)  {
    // if (resultEvent)
    if(resultEvent.failed === true) {
      const errorMessage = 'Upload Failed: ' + resultEvent.statusText;
      console.log(resultEvent);
      dispatch({type: 'error', error_text:errorMessage});
    } else {
      if ( resultEvent.event === 'success' ) {
        // upload successful
        const public_id = resultEvent.info.public_id;
        console.log("uploaded image, publicId: ", public_id);
        setUploadedPublicId(public_id);
      } else if (resultEvent.event === 'upload-added' ) {
        dispatch({type: 'upload_in_progress'});
      }
    }
  }
  
  function handleUploadNewPhoto() {
    dispatch({type: 'reset'});
    setCaptionRetrieveStarted(false);
    setUploadedPublicId(null);
    cloudinaryWidget.open();
  }

  function handleCaptionSubmit(event) {
    event.preventDefault();
    const captionText = event.target.elements.caption.value;
    if(captionText.length > 0) {
      console.log("handleCaptionSubmit, captionText: ", captionText);
      dispatch({type: 'caption_submit', caption_text: captionText});
    }
  }

  function cloudinaryWidgetOpen() {
    cloudinaryWidget.open()
  }

  function selectFaceId(faceId) {
    console.log("selectFaceId: " + faceId);
    dispatch({type:'select_face_id', face_id: faceId});
  }

  function shareImage() {
    //console.log("shareImage, file blob: ", state.fileBlob);
    navigator.share(state.shareInfo.share_data);
  }


  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <img src="/icon_192.png" className={classes.phobbleLogo} alt="phobble logo"/>
          <Typography variant="h5" className={classes.title}>
            Phobble
          </Typography>
        </Toolbar>
      </AppBar>      
      <Container component="main" maxWidth="md" className={classes.mainContainer}>
        <CssBaseline />
          <CurrentStep currentStep={state.currentStep} 
                       errorText={state.errorText}
                       cloudinaryWidgetOpen={cloudinaryWidgetOpen} 
                       handleCaptionSubmit={handleCaptionSubmit} 
                       selectFaceId={selectFaceId}
                       faceSelection={state.faceSelection}
                       handleUploadNewPhoto={handleUploadNewPhoto}
                       shareInfo={state.shareInfo}
                       shareImage={shareImage}
                       finalUrlSave={state.finalUrlSave}
                       finalUrl={state.finalUrl}
                       classes={classes}
                       />
      </Container>
      </ThemeProvider>
  );
}

export default App;
